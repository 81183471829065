import React, { useEffect } from 'react'
import {
  Controller,
  Form,
  FormProvider,
  useFieldArray,
  useForm,
} from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Group, Stack, Text } from '@mantine/core'
import * as yup from 'yup'

import { AttendeeTranscriptOrderCard } from './AttendeeTranscriptOrderCard'
import { ClosingVerbatim } from './ClosingVerbatim'

import { BooleanSelect } from '@/components/common/BooleanSelect'
import { FormErrors } from '@/components/Event/NewScript/components/common/forms/FormErrors'
import { SpellingsForm } from '@/components/Event/NewScript/components/common/forms/SpellingsForm'
import { TimestampsForm } from '@/components/Event/NewScript/components/common/forms/TimestampsForm'
import { NoWrapText } from '@/components/Event/NewScript/components/common/NoWrapText'
import { ActionButton } from '@/components/Event/NewScript/components/common/ScriptTimeline/ActionButton'
import { ActionContainer } from '@/components/Event/NewScript/components/common/ScriptTimeline/ActionContainer'
import { ActionText } from '@/components/Event/NewScript/components/common/ScriptTimeline/ActionText'
import { ActionTitle } from '@/components/Event/NewScript/components/common/ScriptTimeline/ActionTitle'
import { ContinueButton } from '@/components/Event/NewScript/components/common/ScriptTimeline/ContinueButton'
import { ScriptTimeline } from '@/components/Event/NewScript/components/common/ScriptTimeline/ScriptTimeline'
import { TimelineAction } from '@/components/Event/NewScript/components/common/ScriptTimeline/TimelineAction'
import { useScriptState } from '@/components/Event/NewScript/hooks/state/useScriptState'
import { useStepActions } from '@/components/Event/NewScript/hooks/state/useStepActions'
import { useEndZoomEvent } from '@/components/Event/NewScript/hooks/useEndZoomEvent'
import { useStatePolicies } from '@/components/Event/NewScript/hooks/useStatePolicy'
import { closingStepSchema } from '@/components/Event/NewScript/schemas/closingStepSchema'
import { isExaminationUnderOath } from '@/components/Event/NewScript/utils/event'
import { ParrotContact, ScriptStep, TimestampType } from '@/constants'
import { Event } from '@/types'
import { isAttorney } from '@/utils/validations'

export type ClosingStepInterface = yup.InferType<typeof closingStepSchema>

type DefaultClosingFormProps = {
  event: Event
}

export const DefaultClosingForm: React.FC<DefaultClosingFormProps> = ({
  event,
}) => {
  const { state, actions } = useScriptState(event)
  const {
    canContinue,
    completeStepAndContinue,
    completeAction,
    isActionCompleted,
  } = useStepActions(ScriptStep.CLOSING, event)

  const { canWitnessReadOrWaive } = useStatePolicies(event)

  const isEUO = isExaminationUnderOath(event)

  const { confirmAndEndEvent, isLoading } = useEndZoomEvent(event)

  const onContinueClick = () => {
    confirmAndEndEvent(() => {
      completeStepAndContinue(ScriptStep.REVIEW)
    })
  }

  const methods = useForm({
    mode: 'all',
    criteriaMode: 'all',
    resolver: yupResolver(closingStepSchema),
    context: { canWitnessReadOrWaive, isEUO, isDocumentNumberRequired: false },
    defaultValues: {
      witness_requested_transcript:
        state.witness_requested_transcript ?? undefined,
      order_transcript: state.order_transcript,
      attendees: state.attendees,
      is_on_record: state.is_on_record,
      spellings: state.spellings,
      timestamps: state.timestamps,
    },
  })

  const { fields, update } = useFieldArray({
    control: methods.control,
    name: 'attendees',
  })

  useEffect(() => {
    const subscription = methods.watch((value, { name }) => {
      if (
        name === 'witness_requested_transcript' &&
        value.witness_requested_transcript !== undefined
      ) {
        return actions.updateWitnessRequestedTranscript(
          value.witness_requested_transcript,
        )
      }

      if (name === 'order_transcript') {
        return actions.updateOrderTranscript(Boolean(value.order_transcript))
      }

      if (name === 'attendees') {
        return actions.updateAttendees(value.attendees)
      }

      if (name === 'spellings') {
        return actions.updateSpellings(value.spellings)
      }

      if (name === 'timestamps') {
        return actions.updateTimestamps(value.timestamps)
      }
    })
    return () => subscription.unsubscribe()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [methods.watch, state, actions])

  const validateAndCompleteAction = async (
    validation_keys: Array<keyof ClosingStepInterface>,
    action_key: string,
  ) => {
    const results: Promise<boolean>[] = []

    validation_keys.forEach(key => {
      results.push(methods.trigger(key))
    })

    Promise.all(results).then(res => {
      res.every(isValid => isValid) && completeAction(action_key)
    })
  }

  return (
    <FormProvider {...methods}>
      <Form>
        <Stack align="flex-start" spacing="xl">
          <ScriptTimeline title="5. Closing">
            <TimelineAction
              actionKey="clarify_exhibit_and_spelling"
              title="Clarify exhibit and spelling information if necessary"
              onComplete={() => {
                validateAndCompleteAction(
                  ['spellings', 'timestamps'],
                  'clarify_exhibit_and_spelling',
                )
              }}
              completed={isActionCompleted('clarify_exhibit_and_spelling')}
            >
              <ActionContainer outer>
                <Stack>
                  <ActionContainer>
                    <TimestampsForm />
                  </ActionContainer>
                  <ActionContainer>
                    <ActionTitle>Reminder</ActionTitle>
                    <ActionText>
                      If the witness or attorney showed any document during
                      questioning, make sure to clarify if it’s being used as an
                      exhibit or just as a reference.
                    </ActionText>
                  </ActionContainer>
                  <ActionContainer>
                    <SpellingsForm />
                  </ActionContainer>
                  <ActionContainer>
                    <ActionTitle>Reminder</ActionTitle>
                    <ActionText>
                      If the witness or attorney used a name, a location, or a
                      term during questioning that are not common words, ask
                      them to clarify the correct spelling.
                    </ActionText>
                  </ActionContainer>
                </Stack>
              </ActionContainer>
            </TimelineAction>
            {!isEUO && (
              <TimelineAction
                actionKey="collect_information"
                title="Collect transcript information"
                onComplete={() => {
                  validateAndCompleteAction(
                    [
                      'witness_requested_transcript',
                      'order_transcript',
                      'attendees',
                    ],
                    'collect_information',
                  )
                }}
                completed={isActionCompleted('collect_information')}
              >
                {/* Read or waive */}
                {canWitnessReadOrWaive && (
                  <ActionContainer outer>
                    <Stack>
                      <ActionText mono>
                        May I please confirm if the witness will read or waive
                        the transcript of today’s deposition if it is ordered?
                      </ActionText>
                      <ActionContainer>
                        <Stack>
                          <Controller
                            control={methods.control}
                            name={'witness_requested_transcript'}
                            render={({ field: { onChange, value } }) => (
                              <BooleanSelect
                                value={value}
                                onChange={(newValue: boolean | null) =>
                                  onChange(newValue ?? false)
                                }
                                falseLabel="Waive"
                                trueLabel="Read"
                                placeholder="Select option"
                                label="Read or waive"
                                style={{ width: 200 }}
                                error={
                                  !!methods.formState.errors
                                    .witness_requested_transcript?.message
                                }
                              />
                            )}
                          />
                          {methods.formState.errors
                            .witness_requested_transcript !== undefined && (
                            <FormErrors
                              errors={
                                methods.formState.errors
                                  .witness_requested_transcript.message
                              }
                            />
                          )}
                        </Stack>
                      </ActionContainer>
                      <ActionContainer>
                        <ActionTitle>Reminder</ActionTitle>
                        <ActionText>
                          The attorney representing witness may decide on the
                          witness’ behalf if they will read or waive.
                        </ActionText>
                      </ActionContainer>
                    </Stack>
                  </ActionContainer>
                )}

                {/* Are there any Certified transcript order ... */}
                <ActionContainer outer>
                  <Stack>
                    <ActionText mono>
                      Are there any Certified transcript orders at this time?
                    </ActionText>
                    <ActionContainer>
                      <Stack>
                        <Controller
                          control={methods.control}
                          name={'order_transcript'}
                          render={({ field: { onChange, value } }) => (
                            <BooleanSelect
                              value={value}
                              onChange={value => {
                                onChange(value)
                                methods.trigger('order_transcript')
                              }}
                              placeholder="Select option"
                              label="Certified order?"
                              style={{ width: 200 }}
                              error={
                                !!methods.formState.errors.order_transcript
                                  ?.message
                              }
                            />
                          )}
                        />
                        {methods.formState.errors.order_transcript !==
                          undefined && (
                          <FormErrors
                            errors={
                              methods.formState.errors.order_transcript.message
                            }
                          />
                        )}
                      </Stack>
                    </ActionContainer>
                    {state.order_transcript && (
                      <ActionContainer>
                        <ActionText mono>
                          Would you like the standard turnaround of{' '}
                          <NoWrapText>7–10</NoWrapText> business days? We also
                          offer <NoWrapText>3–5</NoWrapText> business days or 24
                          hours. Once you order a certified transcripts now, it
                          cannot be cancelled after.
                        </ActionText>
                      </ActionContainer>
                    )}
                    {state.order_transcript &&
                      fields
                        .filter(
                          attendee =>
                            isAttorney(attendee.role) && attendee.present,
                        )
                        .map(attendee => {
                          const index = fields.findIndex(
                            field => field.id === attendee.id,
                          )

                          return (
                            <AttendeeTranscriptOrderCard
                              isPresenceDisabled={true}
                              key={attendee.id}
                              attendee={attendee}
                              event={event}
                              namePrefix={`attendees.${index}`}
                              error={
                                methods.formState.errors.attendees?.[index]
                                  ?.email?.message
                              }
                              onChange={value => {
                                update(index, value)
                                methods.trigger([
                                  'order_transcript',
                                  `attendees.${index}`,
                                ])
                              }}
                            />
                          )
                        })}
                    <ActionContainer>
                      <ActionTitle>Reminder</ActionTitle>
                      <ActionText>
                        Parrot's standard turnaround time is 7-10 business days.
                        Expedited turnaround times of 3 business days or 1
                        business days are available for an additional cost. All
                        transcripts are delivered electronically. Transcript
                        orders can also be placed after the event on Parrot's
                        website, using a link that will be emailed to the
                        attorneys. Cost inquiries and other questions can be
                        sent to support@parrothq.com. Exhibits can be sent to
                        exhibits@parrothq.com.
                      </ActionText>
                    </ActionContainer>
                  </Stack>
                </ActionContainer>
              </TimelineAction>
            )}
            <TimelineAction
              actionKey="go_off_the_record"
              title="Go off the record and answer remaining questions"
              onComplete={() =>
                validateAndCompleteAction(['is_on_record'], 'go_off_the_record')
              }
              completed={isActionCompleted('go_off_the_record')}
            >
              <ActionContainer outer>
                <Stack>
                  <ActionText mono>
                    <ClosingVerbatim event={event} />
                  </ActionText>
                  <ActionContainer>
                    <Group>
                      <ActionButton
                        onClick={() => {
                          actions.addTimestamp({
                            isOnRecord: false,
                            timestampType: TimestampType.RECORDING_OFF,
                            timezone: event.team.timezone as string,
                          })
                          methods.setValue('is_on_record', false)
                          methods.trigger('is_on_record')
                        }}
                        disabled={!state.is_on_record}
                      >
                        Go off record
                      </ActionButton>
                      {!state.is_on_record && (
                        <Text weight={700} size="xs">
                          You are now off the record.
                        </Text>
                      )}
                    </Group>
                  </ActionContainer>
                  {!!methods.formState.errors.is_on_record?.message && (
                    <FormErrors
                      errors={methods.formState.errors.is_on_record?.message}
                    />
                  )}
                </Stack>
              </ActionContainer>
              <ActionContainer outer>
                <ActionContainer>
                  <ActionTitle>Reminder</ActionTitle>
                  <ActionText>
                    Address any outstanding questions or concerns.
                    <br />
                    Exhibits not already provided should be sent to{' '}
                    {ParrotContact.EXHIBITS_EMAIL}.
                    <br />
                    Transcript orders, cost breakdowns, and other requests
                    should be sent to {ParrotContact.SUPPORT_EMAIL}.
                  </ActionText>
                </ActionContainer>
              </ActionContainer>
            </TimelineAction>
            <ContinueButton
              event={event}
              label="Continue to review"
              canContinue={canContinue()}
              step={ScriptStep.CLOSING}
              handleClick={() => onContinueClick()}
              loading={isLoading}
            />
          </ScriptTimeline>
        </Stack>
      </Form>
    </FormProvider>
  )
}
